<template>
  <div>
    <Dialog
    :width="width"
    :title="title"
    :visible="newvisible"
    @close="closeDialog">
      <div :class="[status===1&&'public-wrap']">
        <div
        class="cabinet-graph"
        ref="cabinetGraphRef"
        v-if="status===1"
        :style="`transform: scale(${rate})`"
        >
          <div
            class="cabinet-area"
            v-for="(device, i) in cabinetList"
            :key="i"
            :style="{
              width: `${device.deviceLayoutW }px`,
              height: `${device.deviceLayoutH }px`
            }"
          >
          <!-- <div class="cabinet-area-header">{{cabinetList[0].deviceTemplateName}}</div> -->
            <div
              class="cell"
              v-for="cell in device.cellList"
              :data-status="cell.cellStatus"
              :data-type="cell.cellFuncType"
              :key="cell.cellCode"
              :style="{
                left: `${cell.cellLayoutX }px`,
                top: `${cell.cellLayoutY }px`,
                width: `${cell.cellLayoutW }px`,
                height: `${cell.cellLayoutH }px`,
                'pointer-events': cell.cellFuncType === 1 ? 'auto' : 'none'
              }"
              tabindex="-1"
            >
            </div>
          </div>
        </div>
        <div v-else>
          <div class="cabinet-table-wrap">
            <h3>{{'柜机规格（'+lockerCode+'）'}}</h3>
            <span class="table-wrap-span">注：P1表示从左至右摆放在第一的位置，P2则表示从左至右摆放在第二的位置，以此类推。</span>
            <Table
              :header-cell-style="{background:'#F7F8FA '}"
              :columns="columns"
              :data="tableData"
              :total="pageParams.total"
              :page="pageParams.pageNum"
              :limit="pageParams.pageSize"
              :hidden="!tableData.length"
              >
                <template #deviceBurn="{originScope}">
                  {{originScope.row.deviceBurn===0?'否':'是'}}
                </template>
                <template #deviceMainSub="{originScope}">
                  {{originScope.row.deviceMainSub===1?'主柜':'副柜'}}
                </template>
                 <template #deviceCellColumns="{ originScope }">
                  <span>{{originScope.row.deviceCellRows+'行'}},&nbsp;&nbsp;</span>
                  <span>{{originScope.row.deviceCellColumns+'列'}}</span>
                </template>
            </Table>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import {
  getTemplate,
  searchListLocker
} from '@/api/modules/device'
import Dialog from '@/components/Dialog'
import Table from '@/components/TablePage'
export default {
  props: {
    deviceTemplateCode: {
      type: String,
      default: ''
    },
    lockerCode: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    status: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: '可视化预览'
    },
    width: {
      type: String,
      default: '650px'
    }
  },
  components: {
    Dialog,
    Table
  },
  data() {
    return {
      cabinetList: [],
      newvisible: false,
      tableData: [],
      pageParams: {
        pageNum: 1,
        pageSize: 10,
        total: 1
      },
      columns: [
        { label: '设备编码', prop: 'deviceCode' },
        { label: '位置', prop: 'devicePosition' },
        { label: '拨号', prop: 'devicePositionNum' },
        { label: '烧录编码', prop: 'deviceBurn' },
        { label: '可视化模版', prop: 'deviceTemplateName' },
        { label: '室内/外柜', prop: 'deviceMainSub' },
        { label: '行/列数', prop: 'deviceCellColumns' },
        { label: '格口规格', prop: 'cellStaticStr' },
      ],
      rate: 1
    }
  },
  watch: {
    visible(newValue) {
      console.log('newValue: ', newValue)
      this.newvisible = newValue
      if (newValue === true) {
        this.getList()
      }
    }
  },
  methods: {
    getList() {
      console.log('getList: ')
      if (this.status === 1) {
        getTemplate({ deviceTemplateCode: this.deviceTemplateCode }).then((res) => {
          console.log('getTemplate-res: ', res)
          this.cabinetList = [res.data] || []
          this.rate = 600 / this.cabinetList[0].deviceLayoutH
          // console.log('rate: ', this.rate)
        })
      } else {
        // console.log('this.lockerCode', this.lockerCode)
        searchListLocker({ lockerCode: this.lockerCode }).then((res) => {
          console.log('searchList4Locker-res: ', res)
          this.tableData = res.data || []
        })
      }
    },
    closeDialog() {
      this.newvisible = false
      console.log('this.newvisible: ', this.newvisible)
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.public-wrap{
  // display: flex;
  // justify-content: center;
  // align-items: center;
  height: 600px;
  overflow-x: auto;
  overflow-y: hidden;
}
.cabinet-graph {
    // user-select: none;
    // flex: 1;
    transform-origin: center top;
    display: flex;
    justify-content: center;
    // flex-direction: row;
    // flex-wrap: nowrap;
    // .cabinet-area-header{
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     z-index: 10;
    //     width: 100%;
    //     height: 36px;
    //     background: #FFFFFF;
    //     box-shadow: 0px 0px 3px 0px rgba(150, 151, 153, 0.5);
    //     line-height: 36px;
    //     text-align: center;
    //   }
    .cabinet-area {
      position: relative;
      flex-shrink: 0;
      margin-right: 2px;
      &:last-of-type {
        margin-right: 0;
      }
      .cell {
        position: absolute;
        background: #D4F0B0;
        overflow: hidden;
        box-sizing: border-box;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        cursor: pointer;
        // box-shadow: 0 0 0 1px inset #fefefe;
        &:focus {
          background: #ffffff;
          color: #85cf26;
          box-shadow: 0 0 0 2px inset #85cf26;
        }
        &:hover::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: #000000;
          opacity: 0.1;
          pointer-events: none;
        }
      }
    }
  }

.cabinet-table-wrap{
  padding: 0 4px;
  h3{
    font-size: 16px;
    font-weight: 500;
    color: #323233;
    line-height: 24px;
  }
  .table-wrap-span{
    font-size: 12px;
    color: #666666;
    line-height: 20px;
    display: block;
    margin: 16px 0 20px 0;
  }
}
</style>
