var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Dialog',{attrs:{"width":_vm.width,"title":_vm.title,"visible":_vm.newvisible},on:{"close":_vm.closeDialog}},[_c('div',{class:[_vm.status===1&&'public-wrap']},[(_vm.status===1)?_c('div',{ref:"cabinetGraphRef",staticClass:"cabinet-graph",style:(("transform: scale(" + _vm.rate + ")"))},_vm._l((_vm.cabinetList),function(device,i){return _c('div',{key:i,staticClass:"cabinet-area",style:({
            width: ((device.deviceLayoutW) + "px"),
            height: ((device.deviceLayoutH) + "px")
          })},_vm._l((device.cellList),function(cell){return _c('div',{key:cell.cellCode,staticClass:"cell",style:({
              left: ((cell.cellLayoutX) + "px"),
              top: ((cell.cellLayoutY) + "px"),
              width: ((cell.cellLayoutW) + "px"),
              height: ((cell.cellLayoutH) + "px"),
              'pointer-events': cell.cellFuncType === 1 ? 'auto' : 'none'
            }),attrs:{"data-status":cell.cellStatus,"data-type":cell.cellFuncType,"tabindex":"-1"}})}),0)}),0):_c('div',[_c('div',{staticClass:"cabinet-table-wrap"},[_c('h3',[_vm._v(_vm._s('柜机规格（'+_vm.lockerCode+'）'))]),_c('span',{staticClass:"table-wrap-span"},[_vm._v("注：P1表示从左至右摆放在第一的位置，P2则表示从左至右摆放在第二的位置，以此类推。")]),_c('Table',{attrs:{"header-cell-style":{background:'#F7F8FA '},"columns":_vm.columns,"data":_vm.tableData,"total":_vm.pageParams.total,"page":_vm.pageParams.pageNum,"limit":_vm.pageParams.pageSize,"hidden":!_vm.tableData.length},scopedSlots:_vm._u([{key:"deviceBurn",fn:function(ref){
            var originScope = ref.originScope;
return [_vm._v(" "+_vm._s(originScope.row.deviceBurn===0?'否':'是')+" ")]}},{key:"deviceMainSub",fn:function(ref){
            var originScope = ref.originScope;
return [_vm._v(" "+_vm._s(originScope.row.deviceMainSub===1?'主柜':'副柜')+" ")]}},{key:"deviceCellColumns",fn:function(ref){
            var originScope = ref.originScope;
return [_c('span',[_vm._v(_vm._s(originScope.row.deviceCellRows+'行')+",  ")]),_c('span',[_vm._v(_vm._s(originScope.row.deviceCellColumns+'列'))])]}}])})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }